import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Grid, Paper, TextField, Typography } from '@mui/material';
import Attachments from '../licenses/LicenseApplication/Attachments/Attachments';
import { LoadingButton } from '@mui/lab';
import { digitalStampRequest } from '../../utils/digitempel-requests';
import { saveAs } from 'file-saver';
import useSharedStyles from '../shared.styles';
import { toast } from 'react-toastify';

const SignDocuments = () => {
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [containerName, setContainerName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const sharedClasses = useSharedStyles();

  const handleDigitalStamp = async () => {
    if (!attachments.length) return;
    setLoading(true);
    const formData = new FormData();
    attachments.forEach((attachment) => {
      formData.append('file', attachment);
    });
    formData.append('use-multipart-names', JSON.stringify(true));

    const response = await digitalStampRequest(formData);

    if (response) {
      saveAs(response, containerName + '.asice');
    } else {
      toast.error(t('error_viga_digitembeldamisel'));
    }
    setLoading(false);
  };

  const handleChangeContainerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContainerName(event.target.value);
  };

  return (
    <div className={sharedClasses.tableContainer}>
      <Paper
        sx={{
          height: '100%',
          marginTop: '8px',
        }}
      >
        <Grid container justifyContent='center' alignItems='center' height='100%'>
          <Grid item container spacing={1} xs={6} justifyContent='flex-end'>
            <Grid item container xs={12} spacing={1} alignItems='center'>
              <Grid item xs={12}>
                <Alert color='info'>{t('silt_digitembelda-õpetus')}</Alert>
              </Grid>
              <Grid item>
                <Typography>{t('silt_digitembelda_konteineri-nimi')}</Typography>
              </Grid>
              <Grid item>
                <TextField onChange={handleChangeContainerName} size='small' />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Attachments
                errors={{
                  error: '',
                }}
                attachments={attachments}
                setAttachments={setAttachments}
                maxFiles={100}
                maxSize={52428800}
                multiple
              />
            </Grid>
            <Grid item>
              <LoadingButton
                variant='contained'
                loading={loading}
                disabled={!attachments.length}
                onClick={handleDigitalStamp}
              >
                {t('nupp_digitembelda')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default SignDocuments;
