import { baseRequest } from './baseRequest';

export const digitalStampRequest = async (formData: FormData): Promise<Blob | null> => {
  try {
    const { status, data } = await baseRequest({
      method: 'POST',
      endpoint: 'api/digitempel',
      responseType: 'blob',
      data: formData,
    });
    if (status === 200) {
      return data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};
