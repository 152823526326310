import makeStyles from '@mui/styles/makeStyles';
import styles from '../../../../styles.module.scss';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  content: {
    minHeight: 80,
    border: `1px solid ${styles.borderGrey}`,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  redBorder: {
    borderColor: styles.red,
  },
  uploadButtonContainer: {
    margin: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hint: {
    marginTop: 8,
    opacity: 0.6
  },
  attachment: {
    width: 'calc(100% - 16px)',
    marginBottom: 8,
    paddingLeft: 16,
    border: '1px solid #A2C3DD',
    borderRadius: 4,
    backgroundColor: '#A2C3DD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      opacity: 0.85
    },
  },
  attachmentTitle: {
    maxWidth: 200,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));

export default useStyles;
