import React from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { AttachFileOutlined, DeleteOutline } from '@mui/icons-material';
import useStyles from './Attachments.styles';

const MAX_FILE_SIZE = 5242880; // 5MB
const MAX_FILES = 5;
const DEFAULT_ACCEPTED_FORMATS = 'image/*,application/pdf';

interface AttachmentsProps {
  errors: { [field: string]: unknown };
  attachments: File[];
  setAttachments: (attachments: File[]) => void;
  maxFiles?: number;
  maxSize?: number;
  acceptedFiles?: string;
  hintText?: string;
  multiple?: boolean;
}

const Attachments = ({
  errors,
  attachments,
  setAttachments,
  maxFiles = MAX_FILES,
  maxSize = MAX_FILE_SIZE,
  acceptedFiles = DEFAULT_ACCEPTED_FORMATS,
  hintText,
  multiple = false,
}: AttachmentsProps) => {
  const classes = useStyles();

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length) {
      const fileList = Array.from(files);
      if (fileList.some((file) => file.size > maxSize)) {
        toast.error('Faili suurus ületab 5 MB');
        return;
      }
      const newAttachments: File[] = [];
      fileList.forEach((file) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          newAttachments.push(file);
          if (newAttachments.length === fileList.length) {
            setAttachments([...attachments, ...newAttachments]);
          }
        };
        fileReader.readAsDataURL(file);
      });
    }
  };

  const handleDelete = (indexToRemove: number) => {
    const newAttachments = attachments.filter(
      (attachment: File, index: number) => index !== indexToRemove
    );
    setAttachments(newAttachments);
  };

  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.content, {
          [classes.redBorder]: !!errors.attachments,
        })}
      >
        <div className={classes.uploadButtonContainer}>
          <input
            id='file-input'
            accept={acceptedFiles}
            multiple={multiple}
            type='file'
            disabled={attachments.length >= maxFiles}
            onChange={handleUpload}
            style={{ display: 'none' }}
          />
          <label htmlFor='file-input' aria-label='Attachments'>
            <Button component='span' color='primary' disabled={attachments.length >= MAX_FILES}>
              <Typography>+</Typography>
              <AttachFileOutlined />
            </Button>
          </label>
        </div>
        {attachments.map((attachment: File, index: number) => (
          <div key={index + attachment.name} className={classes.attachment}>
            <Tooltip title={attachment.name}>
              <Typography className={classes.attachmentTitle}>{attachment.name}</Typography>
            </Tooltip>
            <IconButton onClick={() => handleDelete(index)} color='primary' size='large'>
              <DeleteOutline />
            </IconButton>
          </div>
        ))}
      </div>
      {hintText ? <Typography className={classes.hint}>{hintText}</Typography> : null}
      {/*  * Kui oled välismaalane, palun lisa koopia elamisloast või Eestis väljastatud*/}
      {/*  isikutunnistusest ning koopia maakorraldaja kutsetunnistusest.*/}
      {/*</Typography>*/}
    </div>
  );
};

export default Attachments;
